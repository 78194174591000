import { hasAccessToFeatureInTesting } from '@/variables/feature-testing-constants';

export const whisperAssignmentOpened = 'whisper_assignment_opened';
export const whisperAssignmentClosed = 'whisper_assignment_closed';
export const whisperTimelineOpened = 'whisper_timeline_opened';

export const whisperAssignmentChanges = (eventId: number, string: string | null, data?: object | null) => {
  if (!hasAccessToFeatureInTesting()) return;
  Echo.join(`Event.${eventId}`).whisper(string, data);
};
