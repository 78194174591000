<script setup lang="ts">
import { computed, ref } from 'vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { usePage } from '@inertiajs/vue3';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { UserGroupsResource } from '@/types/inertia';
import { useToast } from 'vue-toastification';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { getIndexFromArrayBasedOnId, sortArrayByTime } from '@/util/globals';
import { ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

const groups = ref(usePage().props.auth.user.groups);
const contextSidebarStore = useContextSidebarStore();

const changeAutoAcceptGroup = async (group: UserGroupsResource) => {
  await axios.patch(`/api/groups/${group.id}/users/${usePage().props.auth.user.id}`, {
    auto_accept: group.auto_accept,
    changeMyself: true,
  });
  const index = getIndexFromArrayBasedOnId(group.id, groups.value);
  if (index > -1) {
    groups.value[index].auto_accept = group.auto_accept;
  }
  useToast().info(group.auto_accept ? 'Auto-Accepting Requests.' : 'No Longer Auto-Accepting Requested.');
};

const toggleActiveFestivalSection = async (festivalSectionInvite) => {
  const index = getIndexFromArrayBasedOnId(festivalSectionInvite.id, festivalSections.value);
  if (index === -1) return;
  await axios.patch(`/api/dashboard/festival-sections/${festivalSections.value[index].festival_section_id}`, {
    is_active: festivalSections.value[index].is_active,
  });
  useToast().info(festivalSections.value[index].is_active ? 'Visible.' : 'Hidden.');
  await contextSidebarStore.fetchFestivalSections();
};
const leaveFestivalSection = async (festivalSectionInvite) => {
  const certain = await useCertaintyModal().assertCertain(
    'Leave ' + festivalSectionInvite.festival_section.name,
    'Are you sure you want to leave ' + festivalSectionInvite.festival_section.name + '? This cannot be undone'
  );
  if (!certain) return;

  await axios.delete(`/api/dashboard/festival-sections/${festivalSectionInvite.festival_section_id}`);
  useToast().warning('You are no longer a member of ' + festivalSectionInvite.festival_section.name + '.');
  const index = getIndexFromArrayBasedOnId(festivalSectionInvite.id, festivalSections.value);
  festivalSections.value.splice(index, 1);
  await contextSidebarStore.fetchFestivalSections();
};
const toggleActiveFestival = async (festivalInvite) => {
  await axios.patch(`/api/dashboard/festivals/${festivalInvite.festival.id}`, {
    is_active: festivalInvite.is_active,
  });
  useToast().info(festivalInvite.is_active ? 'Visible.' : 'Hidden.');
  await contextSidebarStore.fetchFestivals();
};
const leaveFestival = async (festivalInvite) => {
  const certain = await useCertaintyModal().assertCertain(
    'Leave ' + festivalInvite.festival.name,
    'Are you sure you want to leave ' + festivalInvite.festival.name + '? This cannot be undone'
  );
  if (!certain) return;

  await axios.delete(`/api/dashboard/festivals/${festivalInvite.festival.id}`);
  useToast().warning('You are no longer a member of ' + festivalInvite.festival.name + '.');
  const index = getIndexFromArrayBasedOnId(festivalInvite.id, festivalInvites.value);
  festivalInvites.value.splice(index, 1);
  await contextSidebarStore.fetchFestivals();
};
const leaveGroup = async (group: UserGroupsResource) => {
  const certain = await useCertaintyModal().assertCertain(
    'Leave ' + group.name,
    'Are you sure you want to leave ' + group.name + '? This cannot be undone'
  );
  if (!certain) return;

  await axios.post(`/api/profile/groups/${group.id}/leave`);
  useToast().warning('You are no longer a member of ' + group.name + '.');
  setTimeout(() => {
    location.reload();
  }, 1000);
};

const festivalInvites = ref([]);
const festivalSections = ref([]);
const fetchAll = async () => {
  const { data: fSections } = await axios.get('/api/dashboard/festival-sections', {
    params: {
      'only_active': false,
    },
  });
  festivalSections.value = fSections;

  const { data: fInvites } = await axios.get('/api/dashboard/festival-invites');
  festivalInvites.value = fInvites;
};
fetchAll();

const festivalsToShow = computed(() => {
  let festivals = [];

  festivalInvites.value.forEach(function (invite) {
    const index = getIndexFromArrayBasedOnId(invite.festival.id, festivals);
    if (index === -1) {
      festivals.push({
        id: invite.festival.id,
        name: invite.festival.name,
        start: invite.festival.start,
        invite: invite,
        festival_sections: [],
      });
    }
  });

  festivalSections.value.forEach(function (section) {
    const index = getIndexFromArrayBasedOnId(section.festival.id, festivals);
    if (index === -1) {
      festivals.push({
        id: section.festival.id,
        name: section.festival.name,
        start: section.festival.start,
        invite: null,
        festival_sections: [section],
      });
    } else {
      festivals[index].festival_sections.push(section);
    }
  });

  return sortArrayByTime(festivals, 'start');
});

const groupsToShow = computed(() => {
  let array = [];

  groups.value.forEach(function (group) {
    if (group.parent_id) {
      const index = getIndexFromArrayBasedOnId(group.parent_id, array);
      if (index === -1) {
        array.push({
          id: group.parent_id,
          name: group.parent_name,
          group: null,
          sub_groups: [group],
        });
      } else {
        const subGroupIndex = getIndexFromArrayBasedOnId(group.id, array[index].sub_groups);
        if (subGroupIndex === -1) {
          array[index].sub_groups.push(group);
        }
      }
    } else {
      const index = getIndexFromArrayBasedOnId(group.id, array);
      if (index === -1) {
        array.push({
          id: group.id,
          name: group.name,
          group: group,
          sub_groups: [],
        });
      } else {
        if (!array[index].group) {
          array[index].group = group;
        }
      }
    }
  });

  return array;
});

const getGroupActions = (group: unknown) => {
  if (!group) return [];
  return [
    {
      title: 'leave',
      icon: '',
      type: 'warning',
      emphasized: false,
      disabled: group.admin,
      hoverText: group.admin
        ? 'You are an admin of this group. To leave it, please remove your access level and return here.'
        : 'Leave Group',
      action: () => leaveGroup(group),
    },
  ] satisfies ActionButtonProps;
};

const getFestivalActions = (festival: unknown) => {
  if (!festival.invite) return [];
  return [
    {
      title: 'leave',
      icon: '',
      type: 'warning',
      emphasized: false,
      hoverText: 'Leave Festival',
      action: () => leaveFestival(festival.invite),
    },
  ] satisfies ActionButtonProps;
};
</script>
<template>
  <div class="space-y-edge">
    <div class="grid grid-cols-1 gap-edge md:grid-cols-2 [&_.box-container-title-container]:bg-content-secondary">
      <div
        v-if="groupsToShow.length > 0"
        class="space-y-edge"
        :class="{ 'col-span-2': !festivalsToShow.length }">
        <BoxContainer
          v-for="group in groupsToShow"
          :key="group.id"
          :padding-bottom="false"
          class=""
          header-size="h3"
          :content-padding="false"
          :actions="getGroupActions(group.group)"
          :title="group.name">
          <template #afterTitle>
            <DisplayBadge
              text="Group"
              color="primary"
              size="tiny" />
          </template>
          <div
            v-if="group.group"
            :class="group.sub_groups?.length ? 'border-b' : 'rounded-b'"
            class="border-t px-edge">
            <div class="py-edge-1/2">
              <SettingCheck
                v-model="group.group.auto_accept"
                label="Auto Accept Requests"
                :title="
                  'If enabled, all requests from ' +
                  group.name +
                  ' will be automatically accepted. If disabled, you will need to manually accept them.'
                "
                @click="changeAutoAcceptGroup(group.group)" />
            </div>
          </div>
          <div
            v-if="group.sub_groups?.length > 0"
            class="space-y-edge p-edge">
            <BoxContainer
              v-for="subGroup in group.sub_groups"
              :key="subGroup.id"
              :content-padding="false"
              header-size="h3"
              :padding-bottom="false"
              :actions="getGroupActions(subGroup)"
              :title="subGroup.name">
              <div class="border-t px-edge">
                <div class="py-edge-1/2">
                  <SettingCheck
                    v-model="subGroup.auto_accept"
                    label="Auto Accept Requests"
                    :title="
                      'If enabled, all requests from ' +
                      subGroup.name +
                      ' will be automatically accepted. If disabled, you will need to manually accept them.'
                    "
                    @click="changeAutoAcceptGroup(subGroup)" />
                </div>
              </div>
            </BoxContainer>
          </div>
        </BoxContainer>
      </div>

      <div
        v-if="festivalsToShow.length > 0"
        :class="{ 'col-span-2': !groupsToShow.length }"
        class="space-y-edge">
        <BoxContainer
          v-for="festival in festivalsToShow"
          :key="festival.id"
          :padding-bottom="false"
          header-size="h3"
          :content-padding="false"
          :actions="getFestivalActions(festival)"
          :title="festival.name">
          <template #afterTitle>
            <DisplayBadge
              text="Festival"
              color="primary"
              size="tiny" />
          </template>

          <div
            v-if="festival.invite"
            :class="festival.festival_sections?.length ? 'border-b' : 'rounded-b'"
            class="border-t px-edge">
            <div class="py-edge-1/2">
              <SettingCheck
                v-model="festival.invite.is_active"
                label="Visible"
                :title="festival.invite.is_active ? 'Visible' : 'Hidden'"
                @click="toggleActiveFestival(festival.invite)" />
            </div>
          </div>
          <div
            v-if="festival.festival_sections?.length > 0"
            class="space-y-edge p-edge">
            <BoxContainer
              v-for="section in festival.festival_sections"
              :key="section.id"
              :content-padding="false"
              header-size="h4"
              :padding-bottom="false"
              :actions="[
                {
                  title: 'Leave',
                  icon: '',
                  action: () => leaveFestivalSection(section),
                },
              ]"
              :title="section.festival_section.name">
              <div
                v-if="section.admin"
                class="border-t px-edge">
                <div class="w-1/3 py-edge-1/2">
                  <SettingCheck
                    v-model="section.is_active"
                    label="Visible"
                    :title="section.is_active ? 'Visible' : 'Hidden'"
                    @click="toggleActiveFestivalSection(section)" />
                </div>
              </div>
            </BoxContainer>
          </div>
        </BoxContainer>
      </div>
    </div>
  </div>
</template>
